import React from 'react';
import { combineReducers, createStore } from 'redux';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';

import user from '../reducers/user';
import booking from '../reducers/booking';
import coupons from '../reducers/coupons';
import commision from '../reducers/commision';
import messages from '../reducers/messages';
import salesforce from '../reducers/salesforce';
import { GET_USER, SIGN_OUT_USER } from '../actions/user';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'coupons', 'commision'],
};

const reducers = combineReducers({
  user,
  booking,
  coupons,
  commision,
  messages,
  salesforce,
});

// Use a persisted redux store for browser rendering
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer);

export const persistor = persistStore(store, null, () => {
  console.log('Store re-hydrated');
  const STATE = store.getState();
  if (STATE.user.authenticated) {
    console.log('Validate user session...');
    // try fetch user data - if fails, sign out user
    GET_USER(store.dispatch, STATE.user.session.token, STATE.user.session.email)
      .then((result) => {
        if (!result.success) {
          SIGN_OUT_USER(store.dispatch);
        }
      }).catch(() => {
        SIGN_OUT_USER(store.dispatch);
      });
  } else {
    SIGN_OUT_USER(store.dispatch);
  }
});

export const ProviderWithPersistedStore = ({ element }) => { // eslint-disable-line
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  );
};

// Use a non-persisted redux store and provide for SSR
export const nonPersistedStore = createStore(reducers);

export const ProviderWithStore = ({ element }) => {  // eslint-disable-line
  return (
    <Provider store={nonPersistedStore}>
      {element}
    </Provider>
  );
};
