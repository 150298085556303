import { REQUEST, RESPONSE_HANDLER } from 'utilities';

/**
 * Create a new user
 * @param firstName
 * @param email
 * @param password
 * @param session
 * @returns {Promise<*>}
 * @constructor
 */
export const CREATE_USER = async (firstName, email, password, optInNewsletter, session) => {
  const OPTIONS = {
    json: {
      user: {
        first_name: firstName,
        email,
        password,
        opt_in_newsletter: optInNewsletter,
      },
    },
  };
  // include these headers to associate a guest account with a registered account
  if (session && session.is_guest) {
    OPTIONS.headers = {
      token: session.token,
      email: session.email,
    };
  }
  const CREATE = await REQUEST.post('users', OPTIONS);
  return RESPONSE_HANDLER(CREATE);
};

/**
 * Create a guest/anonymous new user
 * @returns {Promise<*>}
 * @constructor
 */
export const CREATE_GUEST_USER = async (dispatch) => {
  const CREATE_GUEST = await REQUEST.post('sessions', {
    json: {
      session: {
        is_guest: true,
        is_admin: false,
        is_supplier: false,
      },
    },
  });
  const RESPONSE = await RESPONSE_HANDLER(CREATE_GUEST);
  if (RESPONSE.success) {
    await dispatch({
      type: 'USER_SET_SESSION',
      payload: {
        authenticated: true,
        session: RESPONSE.data.session,
      },
    });
  }
  return RESPONSE;
};

/**
 * Sign in a user with FB credentials
 * @param facebookAccessToken
 * @param email
 * @param firstName
 * @param session
 * @returns {Promise<*>}
 * @constructor
 */
export const SIGN_IN_USER_FACEBOOK = async (dispatch, facebookAccessToken, email, firstName, session) => {
  const OPTIONS = {
    json: {
      session: {
        facebook: facebookAccessToken,
        first_name: firstName,
        email,
      },
    },
  };
  // include these headers to associate a guest account with a registered account
  if (session && session.is_guest) {
    OPTIONS.headers = {
      token: session.token,
      email: session.email,
    };
  }

  const CREATE = await REQUEST.post('sessions', OPTIONS);

  const RESPONSE = await RESPONSE_HANDLER(CREATE);
  // Check for a token in the response
  if (!RESPONSE.data.session.token) {
    RESPONSE.success = false;
    RESPONSE.data.errors = ['Invalid token for user'];
  }
  if (RESPONSE.success) {
    await dispatch({
      type: 'USER_SET_SESSION',
      payload: {
        authenticated: true,
        session: RESPONSE.data.session,
      },
    });
  }
  return RESPONSE;
};

/**
 * Sign in user and save session
 * @param email
 * @param password
 * @returns {Promise<*>}
 * @constructor
 */
export const SIGN_IN_USER = async (dispatch, email, password, session) => {
  const OPTIONS = {
    json: {
      session: {
        email,
        password,
      },
    },
  };

  // include these headers to associate a guest account with a registered account
  if (session && session.is_guest) {
    OPTIONS.headers = {
      token: session.token,
      email: session.email,
    };
  }
  const SIGN_IN = await REQUEST.post('sessions', OPTIONS);
  const RESPONSE = await RESPONSE_HANDLER(SIGN_IN);
  if (RESPONSE.success) {
    dispatch({
      type: 'USER_SET_SESSION',
      payload: {
        authenticated: true,
        session: RESPONSE.data.session,
      },
    });
  }
  return RESPONSE;
};

/**
 * Sign out user - clear all data
 * @param dispatch
 * @returns {Promise<void>}
 * @constructor
 */
export const SIGN_OUT_USER = (dispatch) => {
  dispatch({
    type: 'USER_RESET',
  });
  dispatch({
    type: 'BOOKINGS_RESET',
  });
};

/**
 * Get user data & update store
 * @param dispatch
 * @param token
 * @param email
 * @returns {Promise<*>}
 * @constructor
 */
export const GET_USER = async (dispatch, token, email) => {
  const GET = await REQUEST.get('users', {
    headers: {
      token,
      email,
    },
  });
  const RESPONSE = await RESPONSE_HANDLER(GET);
  if (RESPONSE.success) {
    const USER = RESPONSE.data.users[0];
    dispatch({
      type: 'USER_SET_DETAILS',
      payload: {
        details: {
          id: USER.id,
          firstName: USER.first_name,
          lastName: USER.last_name,
          isGuest: USER.guest,
          isManager: USER.manager,
          isAdmin: USER.admin,
          isSupplier: USER.supplier,
        },
        favoriteActivities: USER.favorite_addon_ids,
      },
    });
  }
  return RESPONSE;
};

/**
 * Reset users password
 * @param dispatch
 * @param email
 * @returns {Promise<*>}
 * @constructor
 */
export const RESET_PASSWORD = async (dispatch, email) => {
  const RESET = await REQUEST.post('reset_passwords', {
    json: {
      reset_password: {
        email,
      },
    },
  });
  return RESPONSE_HANDLER(RESET);
};

export const CHANGE_PASSWORD = async (token, email, password) => {
  const GET = await REQUEST.get(`users?email=${email}`, {
    headers: {
      token,
      email,
    },
  });
  let RESPONSE = await RESPONSE_HANDLER(GET);
  if (RESPONSE.success) {
    const newPassword = password;
    const user = RESPONSE.data.users[0];
    const UPDATE = await REQUEST.put(`users/${user.id}`, {
      headers: {
        token,
        email,
      },
      json: {
        user: {
          email: user.email,
          first_name: user.first_name,
          password: newPassword,
        },
      },
    });
    RESPONSE = await RESPONSE_HANDLER(UPDATE);
  }
  return RESPONSE;
};

export const UPDATE_USER = async (dispatch, token, email, firstName) => {
  const GET = await REQUEST.get(`users?email=${email}`, {
    headers: {
      token,
      email,
    },
  });

  let RESPONSE = await RESPONSE_HANDLER(GET);

  if (RESPONSE.success) {
    const user = RESPONSE.data.users[0];
    const attrs = {
      first_name: firstName,
    };

    const UPDATE = await REQUEST.put(`users/${user.id}`, {
      headers: {
        token,
        email,
      },
      json: {
        user: attrs,
      },
    });

    RESPONSE = await RESPONSE_HANDLER(UPDATE);

    if (RESPONSE.success && !RESPONSE.data.status) {
      const USER = RESPONSE.data.user;

      dispatch({
        type: 'USER_SET_DETAILS',
        payload: {
          details: {
            id: USER.id,
            firstName: USER.first_name,
          },
          favoriteActivities: USER.favorite_addon_ids,
        },
      });
    }
  }

  return RESPONSE;
};

const removeEmptyValues = (object) => {
  const keys = Object.keys(object);
  let i = 0;

  for (i; i < keys.length; i += 1) {
    const key = keys[i];
    const value = object[key];

    if (value === null || value === undefined || value === '') {
      delete object[key]; // eslint-disable-line
    }
  }

  return object;
};

export const UPDATE_PASSWORD = async (token, email, currentPassword, password, passwordConfirmation) => {
  const GET = await REQUEST.get(`users?email=${email}`, {
    headers: {
      token,
      email,
    },
  });

  let RESPONSE = await RESPONSE_HANDLER(GET);

  if (RESPONSE.success) {
    const user = RESPONSE.data.users[0];
    const data = {
      current_password: currentPassword,
      password,
      password_confirmation: passwordConfirmation,
    };

    const attrs = removeEmptyValues(data);

    const UPDATE = await REQUEST.post(`users/${user.id}/update_password`, {
      headers: {
        token,
        email,
      },
      json: {
        user: attrs,
      },
    });

    RESPONSE = await RESPONSE_HANDLER(UPDATE);
  }

  return RESPONSE;
};

export const DELETE_ACCOUNT = async (dispatch, token, email) => {
  const GET = await REQUEST.get(`users?email=${email}`, {
    headers: {
      token,
      email,
    },
  });

  let RESPONSE = await RESPONSE_HANDLER(GET);

  if (RESPONSE.success) {
    const user = RESPONSE.data.users[0];
    const DELETE = await REQUEST.delete(`users/${user.id}`, {
      headers: {
        token,
        email,
      },
    });

    RESPONSE = await RESPONSE_HANDLER(DELETE);

    if (RESPONSE.success) {
      dispatch({
        type: 'USER_RESET',
      });
    }
  }
};

/**
 * Favourite and Add On for a user
 * @param userID
 * @param addOnID
 * @param email
 * @param token
 * @returns {Promise<*>}
 * @constructor
 */
export const ADD_ADDON_FAV = async (userID, addOnID, email, token) => {
  const ADD = await REQUEST.post(`users/${userID}/add_favorite_addon?addon_id=${addOnID}`, {
    headers: {
      token,
      email,
    },
  });
  return RESPONSE_HANDLER(ADD);
};

/**
 * Remove a favourite
 * @param userID
 * @param addOnID
 * @param email
 * @param token
 * @returns {Promise<*>}
 * @constructor
 */
export const REMOVE_ADDON_FAV = async (userID, addOnID, email, token) => {
  const ADD = await REQUEST.post(`users/${userID}/remove_favorite_addon?addon_id=${addOnID}`, {
    headers: {
      token,
      email,
    },
  });
  return RESPONSE_HANDLER(ADD);
};
