const INITIAL_STATE = {
  FirstName: '',
  LastName: '',
  Phone: '',
  Email: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    /**
     * Set ALL booking data
     */
    case 'SET_SALESFORCE_LEAD_DATA':
      return Object.assign({}, state, {
        ...action.payload,
      });
    default:
      return state;
  }
};
