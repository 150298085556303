const INITIAL_STATE = {
  commision_code: null,
  idev_affiliate_code: null,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    /**
     * Set coupon ALL values
     */
    // case 'COUPONS_SET_ALL':
    //   return Object.assign({}, state, {
    //     coupons: [
    //       ...action.payload,
    //     ],
    //   });
    /**
     * Set coupon values
     */
    case 'SET_COMMISION_CODE':
      return Object.assign({}, state, {
        commision_code: action.payload,
      });
    case 'SET_IDEV_AFFILIATE_CODE':
      return Object.assign({}, state, {
        idev_affiliate_code: action.payload,
      });
    case 'RESET_COMMISION_CODE':
      return INITIAL_STATE;
    case 'RESET_IDEV_AFFILIATE_CODE':
      return INITIAL_STATE;
    default:
      return state;
  }
};
