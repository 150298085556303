const INITIAL_STATE = {
  messages: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'MESSAGES_ADD':
      return Object.assign({}, state, {
        messages: [
          // prevent duplicate messages from showing
          ...state.messages.filter(message => message.id !== action.payload.id),
          action.payload,
        ],
      });
    case 'MESSAGES_REMOVE':
      return Object.assign({}, state, {
        messages: [
          ...state.messages.filter(message => message.id !== action.payload),
        ],
      });
    default:
      return state;
  }
};
