const INITIAL_STATE = {
  authenticated: false,
  session: {
    token: null,
    email: null,
  },
  details: {
    id: '',
    firstName: '',
    lastName: '',
    /*
     A guest is also referred to as an "anonymous" user,
     it means that the user is NOT registered
     */
    isGuest: false,
    isAdmin: false,
    isSupplier: false,
    isManager: false,
  },
  vendor: {
    facebook: {
      userID: null,
      accessToken: null,
      name: '',
    },
    // potential for other vendor integrations
  },
  bookings: {
    bookings: [], // array of bookings
    travellers: [],
  },
  favorites: {
    activities: [], // array of Activity's
    // potential for ability to favourite other types, such as Tour
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    /**
     * Set session
     */
    case 'USER_SET_SESSION':
      return Object.assign({}, state, {
        authenticated: action.payload.authenticated,
        session: {
          ...action.payload.session,
        },
      });

    /**
     * Set user details
     */
    case 'USER_SET_DETAILS':
      return Object.assign({}, state, {
        details: {
          ...action.payload.details,
        },
        favorites: {
          ...state.favorites,
          activities: [
            ...action.payload.favoriteActivities,
          ],
        },
      });

    /**
     * Set bookings
     */
    case 'USER_SET_BOOKINGS':
      return Object.assign({}, state, {
        bookings: {
          bookings: [
            ...action.payload.bookings,
          ],
          travellers: [
            ...action.payload.travellers,
          ],
        },
      });

    /**
     * Add a new booking
     */
    case 'USER_ADD_BOOKING':
      return Object.assign({}, state, {
        bookings: {
          bookings: [
            // ...state.bookings,
            action.payload,
          ],
        },
      });

    /**
     * Reset user
     */
    case 'USER_RESET':
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};
