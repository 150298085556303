import { REQUEST, RESPONSE_HANDLER } from 'utilities/request';
import moment from 'moment';

export const GET_COUPON = async (code) => {
  const GET = await REQUEST(`promo_codes?code=${code}`);
  const RESPONSE = await RESPONSE_HANDLER(GET);
  if (RESPONSE && RESPONSE.success) {
    const NORMALIZE_DATA = [
      ...RESPONSE.data.promo_codes,
    ];
    RESPONSE.data = NORMALIZE_DATA;
  }
  return RESPONSE;
};

/**
 * Remove any in-active or expired coupons
 * @param coupons
 * @param dispatch
 * @returns {Promise<void>}
 * @constructor
 */
export const COUPONS_CLEAN_UP = async (coupons, dispatch) => {
  const VALID_COUPONS = coupons.filter(cp => cp.active && moment(cp.expired_date).isSameOrAfter());
  await dispatch({
    type: 'COUPONS_SET_ALL',
    payload: VALID_COUPONS,
  });
};
