/* eslint-disable */
import { REQUEST, RESPONSE_HANDLER } from './request';

export const getRate = async () => {
  const GET = await REQUEST.get('country_price_rates/get_user_location_rate');
  const RESPONSE = await RESPONSE_HANDLER(GET);

  if (RESPONSE.success) {
    const rate = RESPONSE.data ? RESPONSE.data.rate : 0;
    return rate;
  }
};

export const calculatePriceWithRate = (price, rate) => {
  if (rate) {
    return price + Math.round((price * rate) / 100);
  }

  return price;
};
