// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-affiliate-programme-index-jsx": () => import("./../../../src/pages/about-us/affiliate-programme/index.jsx" /* webpackChunkName: "component---src-pages-about-us-affiliate-programme-index-jsx" */),
  "component---src-pages-about-us-early-bird-snow-promotion-index-jsx": () => import("./../../../src/pages/about-us/early-bird-snow-promotion/index.jsx" /* webpackChunkName: "component---src-pages-about-us-early-bird-snow-promotion-index-jsx" */),
  "component---src-pages-about-us-faqs-index-jsx": () => import("./../../../src/pages/about-us/faqs/index.jsx" /* webpackChunkName: "component---src-pages-about-us-faqs-index-jsx" */),
  "component---src-pages-about-us-inbound-tour-operator-services-index-jsx": () => import("./../../../src/pages/about-us/inbound-tour-operator-services/index.jsx" /* webpackChunkName: "component---src-pages-about-us-inbound-tour-operator-services-index-jsx" */),
  "component---src-pages-about-us-kathmandu-snow-promotion-index-jsx": () => import("./../../../src/pages/about-us/kathmandu-snow-promotion/index.jsx" /* webpackChunkName: "component---src-pages-about-us-kathmandu-snow-promotion-index-jsx" */),
  "component---src-pages-about-us-maori-tour-manager-scholarships-index-jsx": () => import("./../../../src/pages/about-us/maori-tour-manager-scholarships/index.jsx" /* webpackChunkName: "component---src-pages-about-us-maori-tour-manager-scholarships-index-jsx" */),
  "component---src-pages-about-us-my-photos-index-jsx": () => import("./../../../src/pages/about-us/my-photos/index.jsx" /* webpackChunkName: "component---src-pages-about-us-my-photos-index-jsx" */),
  "component---src-pages-about-us-our-awards-index-jsx": () => import("./../../../src/pages/about-us/our-awards/index.jsx" /* webpackChunkName: "component---src-pages-about-us-our-awards-index-jsx" */),
  "component---src-pages-about-us-our-culture-index-jsx": () => import("./../../../src/pages/about-us/our-culture/index.jsx" /* webpackChunkName: "component---src-pages-about-us-our-culture-index-jsx" */),
  "component---src-pages-about-us-our-haka-welcome-haka-and-farewell-index-jsx": () => import("./../../../src/pages/about-us/our-haka-welcome-haka-and-farewell/index.jsx" /* webpackChunkName: "component---src-pages-about-us-our-haka-welcome-haka-and-farewell-index-jsx" */),
  "component---src-pages-about-us-our-people-index-jsx": () => import("./../../../src/pages/about-us/our-people/index.jsx" /* webpackChunkName: "component---src-pages-about-us-our-people-index-jsx" */),
  "component---src-pages-about-us-our-purpose-index-jsx": () => import("./../../../src/pages/about-us/our-purpose/index.jsx" /* webpackChunkName: "component---src-pages-about-us-our-purpose-index-jsx" */),
  "component---src-pages-about-us-our-staff-get-paid-to-learn-maori-index-jsx": () => import("./../../../src/pages/about-us/our-staff-get-paid-to-learn-maori/index.jsx" /* webpackChunkName: "component---src-pages-about-us-our-staff-get-paid-to-learn-maori-index-jsx" */),
  "component---src-pages-about-us-our-story-index-jsx": () => import("./../../../src/pages/about-us/our-story/index.jsx" /* webpackChunkName: "component---src-pages-about-us-our-story-index-jsx" */),
  "component---src-pages-about-us-our-trips-index-jsx": () => import("./../../../src/pages/about-us/our-trips/index.jsx" /* webpackChunkName: "component---src-pages-about-us-our-trips-index-jsx" */),
  "component---src-pages-about-us-our-values-index-jsx": () => import("./../../../src/pages/about-us/our-values/index.jsx" /* webpackChunkName: "component---src-pages-about-us-our-values-index-jsx" */),
  "component---src-pages-about-us-reviews-index-jsx": () => import("./../../../src/pages/about-us/reviews/index.jsx" /* webpackChunkName: "component---src-pages-about-us-reviews-index-jsx" */),
  "component---src-pages-about-us-safety-and-support-index-jsx": () => import("./../../../src/pages/about-us/safety-and-support/index.jsx" /* webpackChunkName: "component---src-pages-about-us-safety-and-support-index-jsx" */),
  "component---src-pages-about-us-schlumberger-index-jsx": () => import("./../../../src/pages/about-us/schlumberger/index.jsx" /* webpackChunkName: "component---src-pages-about-us-schlumberger-index-jsx" */),
  "component---src-pages-accommodation-list-index-jsx": () => import("./../../../src/pages/accommodation-list/index.jsx" /* webpackChunkName: "component---src-pages-accommodation-list-index-jsx" */),
  "component---src-pages-accommodation-on-tour-index-jsx": () => import("./../../../src/pages/accommodation-on-tour/index.jsx" /* webpackChunkName: "component---src-pages-accommodation-on-tour-index-jsx" */),
  "component---src-pages-activities-index-jsx": () => import("./../../../src/pages/activities/index.jsx" /* webpackChunkName: "component---src-pages-activities-index-jsx" */),
  "component---src-pages-activity-brochure-index-jsx": () => import("./../../../src/pages/activity-brochure/index.jsx" /* webpackChunkName: "component---src-pages-activity-brochure-index-jsx" */),
  "component---src-pages-adventure-vs-haka-plus-index-jsx": () => import("./../../../src/pages/adventure-vs-haka-plus/index.jsx" /* webpackChunkName: "component---src-pages-adventure-vs-haka-plus-index-jsx" */),
  "component---src-pages-aroha-aotearoa-index-jsx": () => import("./../../../src/pages/aroha-aotearoa/index.jsx" /* webpackChunkName: "component---src-pages-aroha-aotearoa-index-jsx" */),
  "component---src-pages-bar-tab-and-bungy-giveaway-index-jsx": () => import("./../../../src/pages/bar-tab-and-bungy-giveaway/index.jsx" /* webpackChunkName: "component---src-pages-bar-tab-and-bungy-giveaway-index-jsx" */),
  "component---src-pages-booking-conditions-index-jsx": () => import("./../../../src/pages/booking-conditions/index.jsx" /* webpackChunkName: "component---src-pages-booking-conditions-index-jsx" */),
  "component---src-pages-bookings-add-ons-index-jsx": () => import("./../../../src/pages/bookings/add-ons/index.jsx" /* webpackChunkName: "component---src-pages-bookings-add-ons-index-jsx" */),
  "component---src-pages-bookings-essentials-index-jsx": () => import("./../../../src/pages/bookings/essentials/index.jsx" /* webpackChunkName: "component---src-pages-bookings-essentials-index-jsx" */),
  "component---src-pages-bookings-index-jsx": () => import("./../../../src/pages/bookings/index.jsx" /* webpackChunkName: "component---src-pages-bookings-index-jsx" */),
  "component---src-pages-bookings-payments-error-index-jsx": () => import("./../../../src/pages/bookings/payments/_error/index.jsx" /* webpackChunkName: "component---src-pages-bookings-payments-error-index-jsx" */),
  "component---src-pages-bookings-payments-index-jsx": () => import("./../../../src/pages/bookings/payments/index.jsx" /* webpackChunkName: "component---src-pages-bookings-payments-index-jsx" */),
  "component---src-pages-bookings-payments-success-index-jsx": () => import("./../../../src/pages/bookings/payments/success/index.jsx" /* webpackChunkName: "component---src-pages-bookings-payments-success-index-jsx" */),
  "component---src-pages-bookings-review-index-jsx": () => import("./../../../src/pages/bookings/review/index.jsx" /* webpackChunkName: "component---src-pages-bookings-review-index-jsx" */),
  "component---src-pages-bookings-thank-you-index-jsx": () => import("./../../../src/pages/bookings/thank-you/index.jsx" /* webpackChunkName: "component---src-pages-bookings-thank-you-index-jsx" */),
  "component---src-pages-bookings-traveller-details-index-jsx": () => import("./../../../src/pages/bookings/traveller-details/index.jsx" /* webpackChunkName: "component---src-pages-bookings-traveller-details-index-jsx" */),
  "component---src-pages-born-2-roam-or-hakatours-index-jsx": () => import("./../../../src/pages/born2roam-or-hakatours/index.jsx" /* webpackChunkName: "component---src-pages-born-2-roam-or-hakatours-index-jsx" */),
  "component---src-pages-contact-thank-you-index-jsx": () => import("./../../../src/pages/contact-thank-you/index.jsx" /* webpackChunkName: "component---src-pages-contact-thank-you-index-jsx" */),
  "component---src-pages-contact-us-index-jsx": () => import("./../../../src/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-index-jsx" */),
  "component---src-pages-cookies-policy-index-jsx": () => import("./../../../src/pages/cookies-policy/index.jsx" /* webpackChunkName: "component---src-pages-cookies-policy-index-jsx" */),
  "component---src-pages-departures-index-jsx": () => import("./../../../src/pages/departures/index.jsx" /* webpackChunkName: "component---src-pages-departures-index-jsx" */),
  "component---src-pages-destinations-index-jsx": () => import("./../../../src/pages/destinations/index.jsx" /* webpackChunkName: "component---src-pages-destinations-index-jsx" */),
  "component---src-pages-discounts-index-jsx": () => import("./../../../src/pages/discounts/index.jsx" /* webpackChunkName: "component---src-pages-discounts-index-jsx" */),
  "component---src-pages-early-bird-haka-snow-tours-index-jsx": () => import("./../../../src/pages/early-bird-haka-snow-tours/index.jsx" /* webpackChunkName: "component---src-pages-early-bird-haka-snow-tours-index-jsx" */),
  "component---src-pages-feedback-index-jsx": () => import("./../../../src/pages/feedback/index.jsx" /* webpackChunkName: "component---src-pages-feedback-index-jsx" */),
  "component---src-pages-guide-conference-2023-index-jsx": () => import("./../../../src/pages/guide-conference-2023/index.jsx" /* webpackChunkName: "component---src-pages-guide-conference-2023-index-jsx" */),
  "component---src-pages-haka-tours-declares-climate-emergency-index-jsx": () => import("./../../../src/pages/haka-tours-declares-climate-emergency/index.jsx" /* webpackChunkName: "component---src-pages-haka-tours-declares-climate-emergency-index-jsx" */),
  "component---src-pages-hakatours-gift-voucher-index-jsx": () => import("./../../../src/pages/hakatours-gift-voucher/index.jsx" /* webpackChunkName: "component---src-pages-hakatours-gift-voucher-index-jsx" */),
  "component---src-pages-how-it-works-index-jsx": () => import("./../../../src/pages/how-it-works/index.jsx" /* webpackChunkName: "component---src-pages-how-it-works-index-jsx" */),
  "component---src-pages-included-activities-index-jsx": () => import("./../../../src/pages/included_activities/index.jsx" /* webpackChunkName: "component---src-pages-included-activities-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-itinerary-download-backup-index-jsx": () => import("./../../../src/pages/itinerary-download_backup/index.jsx" /* webpackChunkName: "component---src-pages-itinerary-download-backup-index-jsx" */),
  "component---src-pages-itinerary-download-index-jsx": () => import("./../../../src/pages/itinerary-download/index.jsx" /* webpackChunkName: "component---src-pages-itinerary-download-index-jsx" */),
  "component---src-pages-late-request-index-jsx": () => import("./../../../src/pages/late-request/index.jsx" /* webpackChunkName: "component---src-pages-late-request-index-jsx" */),
  "component---src-pages-lights-camera-action-index-jsx": () => import("./../../../src/pages/lights-camera-action/index.jsx" /* webpackChunkName: "component---src-pages-lights-camera-action-index-jsx" */),
  "component---src-pages-preparing-index-jsx": () => import("./../../../src/pages/preparing/index.jsx" /* webpackChunkName: "component---src-pages-preparing-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-snow-tour-manager-index-jsx": () => import("./../../../src/pages/snow-tour-manager/index.jsx" /* webpackChunkName: "component---src-pages-snow-tour-manager-index-jsx" */),
  "component---src-pages-student-discount-index-jsx": () => import("./../../../src/pages/student-discount/index.jsx" /* webpackChunkName: "component---src-pages-student-discount-index-jsx" */),
  "component---src-pages-summer-quiz-index-jsx": () => import("./../../../src/pages/summer-quiz/index.jsx" /* webpackChunkName: "component---src-pages-summer-quiz-index-jsx" */),
  "component---src-pages-thank-you-index-jsx": () => import("./../../../src/pages/thank-you/index.jsx" /* webpackChunkName: "component---src-pages-thank-you-index-jsx" */),
  "component---src-pages-tour-nz-nature-safari-tour-index-jsx": () => import("./../../../src/pages/tour/nz-nature-safari-tour/index.jsx" /* webpackChunkName: "component---src-pages-tour-nz-nature-safari-tour-index-jsx" */),
  "component---src-pages-tour-snows-best-snow-safari-index-jsx": () => import("./../../../src/pages/tour/snows-best-snow-safari/index.jsx" /* webpackChunkName: "component---src-pages-tour-snows-best-snow-safari-index-jsx" */),
  "component---src-pages-tour-young-adventuress-index-jsx": () => import("./../../../src/pages/tour/young-adventuress/index.jsx" /* webpackChunkName: "component---src-pages-tour-young-adventuress-index-jsx" */),
  "component---src-pages-tours-index-jsx": () => import("./../../../src/pages/tours/index.jsx" /* webpackChunkName: "component---src-pages-tours-index-jsx" */),
  "component---src-pages-tours-search-index-jsx": () => import("./../../../src/pages/tours-search/index.jsx" /* webpackChunkName: "component---src-pages-tours-search-index-jsx" */),
  "component---src-pages-travel-with-confidence-index-jsx": () => import("./../../../src/pages/travel-with-confidence/index.jsx" /* webpackChunkName: "component---src-pages-travel-with-confidence-index-jsx" */),
  "component---src-pages-user-change-password-index-jsx": () => import("./../../../src/pages/user/change-password/index.jsx" /* webpackChunkName: "component---src-pages-user-change-password-index-jsx" */),
  "component---src-pages-user-edit-index-jsx": () => import("./../../../src/pages/user/edit/index.jsx" /* webpackChunkName: "component---src-pages-user-edit-index-jsx" */),
  "component---src-pages-user-favourites-index-jsx": () => import("./../../../src/pages/user/favourites/index.jsx" /* webpackChunkName: "component---src-pages-user-favourites-index-jsx" */),
  "component---src-pages-user-index-jsx": () => import("./../../../src/pages/user/index.jsx" /* webpackChunkName: "component---src-pages-user-index-jsx" */),
  "component---src-pages-user-reset-password-index-jsx": () => import("./../../../src/pages/user/reset-password/index.jsx" /* webpackChunkName: "component---src-pages-user-reset-password-index-jsx" */),
  "component---src-pages-user-sign-in-index-jsx": () => import("./../../../src/pages/user/sign-in/index.jsx" /* webpackChunkName: "component---src-pages-user-sign-in-index-jsx" */),
  "component---src-pages-user-sign-up-index-jsx": () => import("./../../../src/pages/user/sign-up/index.jsx" /* webpackChunkName: "component---src-pages-user-sign-up-index-jsx" */),
  "component---src-pages-winter-sale-index-jsx": () => import("./../../../src/pages/winter-sale/index.jsx" /* webpackChunkName: "component---src-pages-winter-sale-index-jsx" */),
  "component---src-templates-activity-category-index-jsx": () => import("./../../../src/templates/ActivityCategory/index.jsx" /* webpackChunkName: "component---src-templates-activity-category-index-jsx" */),
  "component---src-templates-activity-page-index-jsx": () => import("./../../../src/templates/ActivityPage/index.jsx" /* webpackChunkName: "component---src-templates-activity-page-index-jsx" */),
  "component---src-templates-destination-page-index-jsx": () => import("./../../../src/templates/DestinationPage/index.jsx" /* webpackChunkName: "component---src-templates-destination-page-index-jsx" */),
  "component---src-templates-itinerary-thank-you-index-jsx": () => import("./../../../src/templates/ItineraryThankYou/index.jsx" /* webpackChunkName: "component---src-templates-itinerary-thank-you-index-jsx" */),
  "component---src-templates-tour-category-index-jsx": () => import("./../../../src/templates/TourCategory/index.jsx" /* webpackChunkName: "component---src-templates-tour-category-index-jsx" */),
  "component---src-templates-tour-day-index-jsx": () => import("./../../../src/templates/TourDay/index.jsx" /* webpackChunkName: "component---src-templates-tour-day-index-jsx" */),
  "component---src-templates-tour-page-index-jsx": () => import("./../../../src/templates/TourPage/index.jsx" /* webpackChunkName: "component---src-templates-tour-page-index-jsx" */)
}

