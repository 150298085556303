import moment from 'moment';

/**
 * Is mobile screen
 * The window object is not available at build time - pass through as param
 * @returns {boolean}
 */
export const isMobileScreen = window => window && window.matchMedia('(max-width: 767px)').matches;

/**
 * Is tablet screen
 * The window object is not available at build time - pass through as param
 * @param window
 * @returns {*|boolean}
 */
export const isTabletScreen = window => window && window.matchMedia('(max-width: 991px)').matches;

/**
 * Parse a YouTube or Vimeo URL to extract the ID
 * https://gist.github.com/yangshun/9892961
 * @param url
 * @returns {*}
 */
export const parseVideo = (url) => {
  if (!url) return {};

  let channel;

  // eslint-disable-next-line
  url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\S+)?/);

  if (RegExp.$3.indexOf('youtu') > -1) {
    channel = 'youtube';
  } else if (RegExp.$3.indexOf('vimeo') > -1) {
    channel = 'vimeo';
  }

  const videoId = RegExp.$6;

  return {
    channel,
    videoId,
    previewUrl: `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`,
  };
};

/**
 * Convert a number to a currency string
 * @param number
 * @param decimals
 * @returns {*}
 */
export const numberToCurrency = (number, decimals = 0) => {
  let num = number;

  if (typeof num === 'string') {
    // Remove commas and spaces from string
    num = parseFloat(num.replace(/[,\s]/g, ''));
  }

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(num);
};

/**
 * Create a url to retrieve an image from the image provider (imgix)
 * @param id
 * @param images
 * @param width
 * @param height
 * @param retina
 * @returns {string}
 */
export const getImageUrl = ({
  id,
  images,
  width = null,
  height = null,
  retina = false,
}) => {
  if (!Array.isArray(images)) return '';

  let url = '';

  const imageObj = images
    .map(image => image.node || image)
    .find(image => id === image.api_id);

  if (imageObj && imageObj.imgix_url) {
    url = imageObj.imgix_url;

    if (width || height) {
      url += '?';

      if (width) {
        url += `w=${retina ? width * 2 : width}`;
      }

      if (height) {
        url += width ? '&' : '';
        url += `h=${retina ? height * 2 : height}`;
      }

      url += '&fit=crop&auto=format';
    } else {
      url += '?auto=format';
    }
  }

  return url;
};

export const getImageAltText = ({
  id,
  images,
}) => {
  if (!Array.isArray(images)) return '';

  let altText = '';

  const imageObj = images
    .map(image => image.node || image)
    .find(image => id === image.api_id);

  if (imageObj) {
    altText = imageObj.alt_text;
  }

  return altText;
};

export const filterSeasonalPricing = (seasonalPricings) => {
  const currentDate = moment().startOf('day');

  const priceList = [];

  // Loop through the seasonalPricings array
  // eslint-disable-next-line no-restricted-syntax
  for (const pricing of seasonalPricings) {
    const availableFrom = pricing.available_from;
    const availableTo = pricing.available_to;

    if (
      (availableFrom && availableTo && currentDate.isSameOrAfter(availableFrom) && currentDate.isSameOrBefore(availableTo))
      || (availableFrom && currentDate.isSameOrAfter(availableTo))
      || (availableTo && currentDate.isSameOrBefore(availableTo))) {
      priceList.push(pricing);
    }
  }
  return priceList;
};

/**
 * Get the min and max option prices for an activity
 * @param options
 * @returns {*}
 */
export const minMaxOptionPrices = (options) => {
  if (!Array.isArray(options)) return null;

  const prices = [];

  options.filter(option => option.hidden === false).filter(option => option.archived === false).forEach((item) => {
    prices.push(item.price);
    if (item.seasonal_pricings && item.seasonal_pricings.length > 0) {
      const seasonalPricings = filterSeasonalPricing(item.seasonal_pricings);
      seasonalPricings.forEach((seasonal) => {
        prices.push(seasonal.price);
      });
    }
  });

  return {
    all: prices,
    min: Math.min(...prices),
    max: Math.max(...prices),
  };
};

export const minMaxSeasonalPrices = (options) => {
  if (!Array.isArray(options)) return null;

  const prices = [];

  options.forEach((item) => {
    prices.push(item.price);
  });

  return {
    all: prices,
    min: Math.min(...prices),
    max: Math.max(...prices),
  };
};

export const getSeasonPriceString = (item) => {
  let availability = null;
  if (item.available_from && item.available_to) {
    availability = `$${item.price} (Available From ${moment(item.available_from).format('DD MMM YYYY')} until ${moment(item.available_to).format('DD MMM YYYY')})`;
  } else if (item.available_from) {
    availability = `$${item.price} (Available From ${moment(item.available_from).format('DD MMM YYYY')})`;
  } else if (item.available_to) {
    availability = `$${item.price} (Available Until ${moment(item.available_to).format('DD MMM YYYY')})`;
  }
  return availability;
};

/**
 * Get the number of days from now
 * @param start
 * @returns {number}
 */
export const daysFromNow = (start) => {
  const a = moment(start);
  const b = moment();
  return Math.abs(b.diff(a, 'days'));
};

/**
 * Get days between 2 dates
 * @param start
 * @param end
 * @returns {number}
 * @constructor
 */
export const DAYS_BETWEEN = (start, end) => {
  const a = moment(start);
  const b = moment(end);
  return Math.abs(b.diff(a, 'days'));
};

export const DATE_IN_BETWEEN = (pointer, day, start, end) => {
  let result = true;
  if (start == null && end == null) {
    result = true;
  } else if (start && end == null) {
    result = moment(pointer).add(day, 'days').isSameOrAfter(start);
  } else if (start == null && end) {
    result = moment(pointer).add(day, 'days').isSameOrBefore(end);
  } else {
    result = moment(pointer).add(day, 'days').isBetween(start, end, null, '[]');
  }
  return result;
};

/**
 * Format GraphQL data
 * @param data
 * @returns {array}
 */
export const FORMAT_GRAPH_DATA = data => data.edges.map(dataPoint => dataPoint.node);
