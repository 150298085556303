import ky from './ky';

// import { API_URL } from '../config';

export const REQUEST = ky.extend({
  prefixUrl: process.env.GATSBY_API_URL,
  timeout: 20000,
  headers: {
    'content-type': 'application/json',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
  },
});

export const SALESFORCE_REQUEST = ky.extend({
  timeout: 20000,
  headers: {
    'content-type': 'application/json',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
  },
});

/**
 * Generic response handle for most API requests
 * @param response
 * @returns {Promise<*>}
 * @constructor
 */
export const RESPONSE_HANDLER = async (response) => {
  let data;
  const CONTENT_TYPE = response.headers.get('content-type');
  if (CONTENT_TYPE && CONTENT_TYPE.includes('application/json')) {
    data = await response.json();
  }
  return {
    success: (response && response.ok),
    data,
  };
};

/**
 * Handle consistent error responses from API - normalize into an Array
 * e.g. {"errors":{"email":["is already taken"]}}
 * @param errors
 * @returns {Array}
 * @constructor
 */
export const RESPONSE_ERROR_FORMATTER = (errors) => {
  if (Array.isArray(errors)) return errors;
  if (typeof errors === 'object') {
    return Object.keys(errors).map((key) => {
      if (Array.isArray(errors[key])) {
        return `${key} ${errors[key].join(' - ')}`;
      }
      return `${key} ${errors[key]}`; // assume string
    });
  }
  // assume string
  return [errors];
};
