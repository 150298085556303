const INITIAL_STATE = {
  currentBooking: null,
  travellers: [],
  accommodations: [],
  upgradeAccommodations: [],
  addOns: [],
  paymentCards: [],
  commision_factory_code: null,
  updating: false, // used for showing a loading state
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    /**
     * Set ALL booking data
     */
    case 'BOOKINGS_SET_ALL':
      return Object.assign({}, state, {
        ...action.payload,
        addOns: action.payload.addOns || [],
      });

    /**
     * Set current booking
     */
    case 'BOOKINGS_SET_BOOKING':
      return Object.assign({}, state, {
        currentBooking: {
          ...action.payload,
        },
      });

    /**
     * Set travellers
     */
    case 'BOOKINGS_SET_TRAVELLERS':
      return Object.assign({}, state, {
        travellers: [
          ...action.payload,
        ],
      });

    /**
     * Set updating
     */
    case 'BOOKINGS_SET_UPDATING':
      return Object.assign({}, state, {
        updating: action.payload,
      });

    case 'SET_COMMISION_FACTORY_CODE':
      return Object.assign({}, state, {
        commision_factory_code: action.payload,
      });

    /**
     * Set current booking
     */
    case 'BOOKINGS_RESET':
      return INITIAL_STATE;

    default:
      return state;
  }
};
