/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'formdata-polyfill';

import { navigate } from 'gatsby';
import queryString from 'query-string';
import {
  PAGES,
  COUPON_PARAM_NAME,
  COMMISION_FACTORY,
  IDEV_AFFILIATE,
  SUBSCRIBED_NEWS_LETTER,
} from 'config';
import { GET_COUPON } from 'actions/coupons';

import { ProviderWithPersistedStore, store } from 'store';

/**
 * Handle redirect URLs from PX Pay - we're supporting the format that the old
 * app supports to ensure the payment works before launch.
 *
 * Example success URL: /bookings/[bookingID]/payments/[paymentID]/confirm?success=true&result=212121
 * Example error URL: /bookings/[booking.id]/payments/[payment_id]/confirm?success=false&result=1131313
 * @param location
 */
const handlePaymentRedirect = (location) => {
  const PARSED = queryString.parse(window.location.search);
  const PARTS = location.pathname.split('/').filter(part => part !== '');

  if (PARTS[0] === 'bookings' && PARTS[2] === 'payments' && PARTS[PARTS.length - 1] === 'confirm') {
    console.log('URL is from PX Pay redirect...');
    const PARAMS = `bookingId=${PARTS[1]}&paymentId=${PARTS[3]}&result=${PARSED.result}`;
    if (PARSED.success) {
      navigate(`${PAGES.BOOKINGS_PAYMENT_SUCCESS.PATH}?${PARAMS}`);
    } else {
      navigate(`${PAGES.BOOKINGS_PAYMENT_ERROR.PATH}?${PARAMS}`);
    }
  }
};
/**
 * Handle commision factory
 */
const handleCommisionFactory = (location) => {
  const PARSED = queryString.parse(location.search);
  if (PARSED[COMMISION_FACTORY]) {
    store.dispatch({
      type: 'SET_COMMISION_CODE',
      payload: PARSED[COMMISION_FACTORY],
    });
  }
};

const handleIDevAffiliate = (location) => {
  const PARSED = queryString.parse(location.search);
  if (PARSED[IDEV_AFFILIATE]) {
    store.dispatch({
      type: 'SET_IDEV_AFFILIATE_CODE',
      payload: PARSED[IDEV_AFFILIATE],
    });
  }
};

/**
 * Handle a coupon URL
 */
const handleCoupon = (location) => {
  const PARSED = queryString.parse(location.search);
  if (PARSED[COUPON_PARAM_NAME]) {
    GET_COUPON(PARSED[COUPON_PARAM_NAME]).then((result) => {
      if (result && result.success && result.data.length) {
        const COUPON = result.data[0];
        let MESSAGE = `Success! Promo Code <strong>${COUPON.code}</strong> applied. Make tour booking to see your discount applied.`;
        let MESSAGE_TYPE = 'success';
        if (COUPON.is_expired) {
          MESSAGE = 'Sorry - this promo code has now expired';
          MESSAGE_TYPE = 'error';
        }
        store.dispatch({
          type: 'COUPONS_SET_COUPON',
          payload: COUPON,
        });

        store.dispatch({
          type: 'MESSAGES_ADD',
          payload: {
            id: COUPON.code,
            type: MESSAGE_TYPE,
            content: MESSAGE,
          },
        });
        setTimeout(() => {
          store.dispatch({
            type: 'MESSAGES_REMOVE',
            payload: COUPON.code,
          });
        }, 5000);
      }
    });
  }
};

/**
 * Handle when user subscibed to newsletter
 */
const handleSubscribeNewsLetter = (location) => {
  const PARSED = queryString.parse(location.search);
  if (PARSED[SUBSCRIBED_NEWS_LETTER]) {
    store.dispatch({
      type: 'MESSAGES_ADD',
      payload: {
        id: 'subscribe_news_letter',
        type: 'success',
        content: 'Awesome! You are now signed up to receive our newsletter.',
      },
    });
    setTimeout(() => {
      store.dispatch({
        type: 'MESSAGES_REMOVE',
        payload: 'subscribe_news_letter',
      });
    }, 7000);
  }
};

export const onPreRouteUpdate = ({ location }) => {
  handlePaymentRedirect(location);
};

export const onInitialClientRender = () => {
  handleCoupon(window.location);
  handleCommisionFactory(window.location);
  handleIDevAffiliate(window.location);
  handleSubscribeNewsLetter(window.location);
};

export const wrapRootElement = ProviderWithPersistedStore; // eslint-disable-line

export const onClientEntry = () => {
  // Don't need to do anything here, but if you don't
  // export something, the import won't work.
};
