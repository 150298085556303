const INITIAL_STATE = {
  coupons: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    /**
     * Set coupon ALL values
     */
    case 'COUPONS_SET_ALL':
      return Object.assign({}, state, {
        coupons: [
          ...action.payload,
        ],
      });
    /**
     * Set coupon values
     */
    case 'COUPONS_SET_COUPON':
      return Object.assign({}, state, {
        coupons: [
          ...state.coupons,
          {
            ...action.payload,
          },
        ],
      });
    default:
      return state;
  }
};
